$action-bar: 4rem;
$top-nav: 6rem;
$side-panel: 8.2rem;
$side-panel-right: 25.6rem;
$side-panel-expanded: calc($side-panel-right + #{$side-panel});
$border-radius: 0.3rem;
$notifications-panel: 50.4rem;

// Spaces
$spacer-xxs: 0.2rem;
$spacer-xs: 0.4rem;
$spacer-s: 0.8rem;
$spacer-m: 1.2rem;
$spacer-l: 1.6rem;
$spacer-xl: 2.4rem;
$spacer-xxl: 3.2rem;
$spacer-xxxl: 4rem;
$spacer-4xl: 4.8rem;
$spacer-5xl: 5.6rem;
$spacer-6xl: 6.4rem;
$spacer-7xl: 7.2rem;

// Settings
$desktop-settings-spacing: 2rem;
$mobile-settings-spacing: 1.5rem;

// Border-radius
$border-radius-s: 0.2rem;
$border-radius-m: 0.4rem;
$border-radius-l: 0.8rem;
$border-radius-full: 9999px;

// Button heights
$button-small-height: 2.4rem;
$button-default-height: 2.8rem;
$button-large-height: 3.2rem;
