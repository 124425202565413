@import '../../../styles/variables.scss';

.sidebar__animation {
  transition:
    transform 0.2s cubic-bezier(0.4, 0, 1, 1),
    width 0.2s;
  left: 0;
}

.sidebar__animation.ng-hide {
  left: -#{$side-panel-expanded};
  height: 0;
}

.sidebar-site-animation {
  transition:
    transform 0.2s cubic-bezier(0.4, 0, 1, 1),
    all 0.2s;
}

.filterPanel {
  z-index: $z-index-filter-panel;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: $side-panel;
  height: 100%;
  overflow: hidden;
  scrollbar-color: $scrollbar-color $transparent;
  scrollbar-width: thin;
  color: $panel-font;

  .side-panel-divider {
    width: 100%;
    height: 0.1rem;
    background: $main-black;
  }

  .defects-edit & {
    padding-top: $spacer-xl;
  }

  .sidebar-backdrop {
    height: 100%;
    width: calc(100% - #{$side-panel-expanded});
    position: fixed;
    top: 0;
    left: $side-panel-expanded;
  }
}

.sidePanelBody--expanded {
  width: $side-panel-expanded !important;
  z-index: $z-index-side-panel;
  box-shadow: $spacer-s 0 $spacer-xxl (-$spacer-s) $black-40;
}

.sidePanelBody--collapsedMobile {
  width: 0 !important;
  z-index: 500;

  .sidePanel__left {
    width: 0 !important;
    padding: 0 !important;
  }
}
