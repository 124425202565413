@use '../variables' as *;

.headingLarge {
  font-size: 3.8rem;
  font-weight: $font-reg;
}

.headingLargeBold {
  font-size: 3.8rem;
  font-weight: $font-semibold;
}

.copyHeadingReg {
  font-size: $font-medium;
  line-height: 2rem;
  font-family: var(--fontFamily), serif;
  font-weight: $font-reg;
}

.copy {
  font-size: $font-size-default;
  line-height: $spacer-l;
  font-family: var(--fontFamily), serif;
  font-weight: $font-semibold;
}

.copyReg {
  font-size: $font-size-default;
  line-height: $spacer-l;
  font-family: var(--fontFamily), serif;
  font-weight: $font-reg;
}

.copySmall {
  font-size: $font-small;
  line-height: $spacer-l;
  font-family: var(--fontFamily), serif;
  font-weight: $font-semibold;
}

.copySmallReg {
  font-size: $font-small;
  line-height: $spacer-l;
  font-family: var(--fontFamily), serif;
  font-weight: $font-reg;
}

.siteTableFont {
  font-size: 13px;
  font-weight: $font-reg;
}

.siteTableFont--semibold {
  @extend .siteTableFont;
  font-weight: $font-semibold;
}

.siteTable {
  font-size: 12px;
  line-height: 16px;
  font-family: var(--fontFamily), serif;
  font-weight: $font-semibold;
}

.text-body-xs {
  font-size: $spacer-s;
  font-family: var(--fontFamily), serif;
  font-weight: $font-reg;
}

.placeholder-text {
  font-weight: $font-thin;
  color: $grey-400;
}

.activeStateInput {
  outline: $active-state-outline;
  border: 0.1rem solid var(--primary600);

  &:hover {
    border: 0.1rem solid var(--primary600);
  }

  ::ng-deep {
    .pointCF__dropdown-icon--hide-hover {
      display: none;
    }

    .saveIndicator__clear {
      display: flex;
    }
  }
}

.disabledStateInput__filled {
  background-color: $grey-300;
  border-color: $grey-300;
}

.disabledStateInput {
  outline: none !important;
  border: $thin-grey-300-border !important;
  background: $grey-100 !important;
  cursor: default !important;
  color: $grey-400 !important;

  input {
    color: $grey-400 !important;
    background-color: $grey-100;
    cursor: default;
  }

  &:hover {
    border-color: $grey-300;
  }
}

.rotateAnimation {
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  -webkit-transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  -o-transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.body-xl {
  font-size: $font-medium;
  font-style: normal;
  font-weight: $font-reg;
  line-height: normal;
  font-feature-settings:
    'clig' off,
    'liga' off;
}

.body-l {
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: $font-size-default;
  font-style: normal;
  font-weight: $font-reg;
  line-height: normal;
}

.body-xs {
  font-size: 1rem;
  font-style: normal;
  font-weight: $font-reg;
  line-height: normal;
  font-feature-settings:
    'clig' off,
    'liga' off;
}

.body-m {
  font-size: $font-table;
  font-style: normal;
  font-weight: $font-reg;
  line-height: normal;
  font-feature-settings:
    'clig' off,
    'liga' off;
}

.body-s {
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: $font-small;
  font-style: normal;
  font-weight: $font-reg;
  line-height: normal;
}

.header-s {
  font-feature-settings:
    'liga' off,
    'clig' off;

  font-size: $font-header;
  font-style: normal;
  line-height: normal;
}

.header-m {
  font-feature-settings:
    'liga' off,
    'clig' off;
  font-size: $font-size-large;
  font-style: normal;
  line-height: normal;
}
