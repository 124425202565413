@import '../variables.scss';
@import './quill.scss';
@import './dashboard.scss';
@import './scrollbars.scss';
@import '/src/app/project/modules/site/site-timeline/body-elements/timeline-variables.scss';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline: 0 !important;
}

html {
  overflow: hidden;
  scrollbar-color: $grey-400 $transparent;
  scrollbar-width: thin;
  height: 100%;
}

body {
  background-color: $main-black;
  min-height: 100%;
  height: 100%;
}

body.inheritCursors * {
  cursor: inherit !important;
}

.full-height {
  height: 100%;

  @include mobile-and-down {
    height: 100vh;
  }

  @include desktop-and-up {
    overflow-y: hidden;
  }
}

.content-panel {
  margin: $top-nav 0 10rem $side-panel-expanded;
  padding: $spacer-xxl 0 0 $spacer-xxl;

  &.content-panel-collapsed {
    min-height: calc(100vh - #{$top-nav});
    margin: $top-nav 0 0 $side-panel;
    padding: 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: $spacer-s;
  height: $spacer-s;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $grey-400;
  border-radius: $border-radius-m;
}

// Remove default Edge input preview icon
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

// TOOLTIPS

.tooltipAvatar__wrapper {
  margin-right: $spacer-xs;
}

.tooltipAvatar {
  width: $spacer-xl;
  height: $spacer-xl;
  border-radius: $border-radius-full;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.labelTooltipAvatar {
  width: $spacer-xxl;
  height: $spacer-xxl;
  border-radius: $border-radius-full;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.tooltipTableRow {
  text-align: left;
  display: table-row;
}

.tooltipTable {
  margin: $spacer-xxs $spacer-xs $spacer-xxs $spacer-xs;
  border-spacing: 0 $spacer-xs;
}

.tooltipTableCell {
  overflow: hidden;
  text-overflow: ellipsis;
  display: table-cell;
  vertical-align: middle;
  margin-left: $spacer-s;
}

.tooltipTableAvatar {
  margin-right: $spacer-s;
  display: inline-block;
  vertical-align: top;
  border: $thin-white-border;
  margin-top: $spacer-xxs;
  margin-bottom: $spacer-xxs;
}

.tooltipText {
  width: 16rem;
}

.tooltipText--short {
  max-width: 16rem;
}

.tooltipDate {
  width: 6rem;
}

.tooltip_tagList {
  text-align: left;
}

.tooltipTag:first-of-type {
  margin-left: 0 !important;
}

.tooltipInnerCell {
  display: block;

  // text-overflow: ellipsis; // If we want to trim long names
  // overflow: hidden;
  // height: $spacer-xl;
}

.labelTooltip__userName {
  color: $grey-900;
  display: block;
}

.labelTooltip__email {
  color: $grey-400;
  display: block;
}

.tooltip__fleetStatuses {
  width: 23rem;
  padding: $spacer-m $spacer-s;
}

.tooltipFormula {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  align-items: center;
  gap: 10px;
}

.tooltipFormula__operator {
  @extend .body-s;

  font-weight: $font-semibold;
  color: $white;
}

.tooltipFormula__letterF {
  @extend .body-s;

  font-weight: $font-semibold;
  color: $white;

  font-style: italic !important;
}

.tooltipFormula__customField {
  @extend .body-s;

  display: flex;
  height: 20px;
  padding: $spacer-xxs $spacer-xs;
  gap: $spacer-xs;
  color: var(--primary600);
  border-radius: 4px;
  border: 0.1rem solid var(--primary200);
  background: $white;

  max-width: 100px;
  justify-content: center;
  align-items: center;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  svg {
    min-width: 1.4rem;
  }
}

.tippy-box[data-theme~='pinpoint'] {
  background-color: $black-80a;
  color: $white;
  border-radius: $border-radius-m;
  // padding: $spacer-xs;
}

.tippy-box[data-theme~='pinpoint'][data-placement^='bottom'] .tippy-arrow::before {
  border-bottom-color: $black-80a;
  top: -$spacer-s;
}

.tippy-box[data-theme~='pinpoint'][data-placement^='top'] .tippy-arrow::before {
  border-top-color: $black-80a;
  bottom: -$spacer-s;
}

.tippy-box[data-theme~='pinpoint'][data-placement^='left'] .tippy-arrow::before {
  border-left-color: $black-80a;
  right: -$spacer-s;
}

.tippy-box[data-theme~='pinpoint'][data-placement^='right'] .tippy-arrow::before {
  border-right-color: $black-80a;
  left: -$spacer-s;
}

.tippy-box[data-theme~='labelUser'][data-placement^='bottom-start'] {
  border-radius: $spacer-s;
  background: $white;
  box-shadow: 0px $spacer-xs $spacer-s 0px $black-25;
}

// tippy default styles
.tippy-box {
  border: 0.1rem $transparent;
  position: relative;
  background-color: $gray-1;
  color: $white;
  border-radius: $border-radius-m;
  font-size: $font-size-default;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
}
.tippy-box[data-placement^='top'] > .tippy-arrow:after {
  border-top-color: inherit;
  border-width: $spacer-s $spacer-s 0;
  bottom: -$spacer-s;
  left: 0;
}
.tippy-box[data-placement^='bottom'] > .tippy-arrow:after {
  border-bottom-color: inherit;
  border-width: 0 $spacer-s $spacer-s;
  top: -$spacer-s;
  left: 0;
}
.tippy-box[data-placement^='left'] > .tippy-arrow:after {
  border-left-color: inherit;
  border-width: $spacer-s 0 $spacer-s $spacer-s;
  right: -$spacer-s;
  top: 0;
}
.tippy-box[data-placement^='right'] > .tippy-arrow:after {
  border-width: $spacer-s $spacer-s $spacer-s 0;
  left: -$spacer-s;
  top: 0;
  border-right-color: inherit;
}
.tippy-box[data-placement^='top'] > .tippy-svg-arrow > svg:first-child:not(:last-child) {
  top: $spacer-l;
}
.tippy-box[data-placement^='bottom'] > .tippy-svg-arrow > svg:first-child:not(:last-child) {
  bottom: $spacer-l;
}
.tippy-box[data-placement^='left'] > .tippy-svg-arrow > svg:first-child:not(:last-child) {
  left: $spacer-m;
}
.tippy-box[data-placement^='right'] > .tippy-svg-arrow > svg:first-child:not(:last-child) {
  right: $spacer-m;
}
.tippy-arrow {
  border-color: inherit;
  width: $spacer-l;
  height: $spacer-l;
  color: $gray-1;
}
.tippy-arrow:after {
  content: '';
  z-index: -1;
  position: absolute;
  border-color: $transparent;
  border-style: solid;
}
.tippy-box[data-placement^='top'] > .tippy-backdrop {
  transform-origin: 0 25%;
  border-radius: 40% 40% 0 0;
}
.tippy-box[data-placement^='top'] > .tippy-backdrop[data-state='visible'] {
  transform: scale(1) translate(-50%, -55%);
}
.tippy-box[data-placement^='top'] > .tippy-backdrop[data-state='hidden'] {
  transform: scale(0.2) translate(-50%, -45%);
}
.tippy-box[data-placement^='bottom'] > .tippy-backdrop {
  transform-origin: 0 -50%;
  border-radius: 0 0 30% 30%;
}
.tippy-box[data-placement^='bottom'] > .tippy-backdrop[data-state='visible'] {
  transform: scale(1) translate(-50%, -45%);
}
.tippy-box[data-placement^='bottom'] > .tippy-backdrop[data-state='hidden'] {
  transform: scale(0.2) translate(-50%);
}
.tippy-box[data-placement^='left'] > .tippy-backdrop {
  transform-origin: 50% 0;
  border-radius: $border-radius-full 0 0 50%;
}
.tippy-box[data-placement^='left'] > .tippy-backdrop[data-state='visible'] {
  transform: scale(1) translate(-50%, -50%);
}
.tippy-box[data-placement^='left'] > .tippy-backdrop[data-state='hidden'] {
  transform: scale(0.2) translate(-75%, -50%);
}
.tippy-box[data-placement^='right'] > .tippy-backdrop {
  transform-origin: -50% 0;
  border-radius: 0 50% 50% 0;
}
.tippy-box[data-placement^='right'] > .tippy-backdrop[data-state='visible'] {
  transform: scale(1) translate(-50%, -50%);
}
.tippy-box[data-placement^='right'] > .tippy-backdrop[data-state='hidden'] {
  transform: scale(0.2) translate(-25%, -50%);
}
.tippy-box[data-animatefill] {
  background-color: $transparent !important;
}
.tippy-backdrop {
  position: absolute;
  background-color: $gray-1;
  border-radius: $border-radius-full;
  width: calc(110% + $spacer-xxl);
  left: 50%;
  top: 50%;
  z-index: -1;
  transition: all cubic-bezier(0.46, 0.1, 0.52, 0.98);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.tippy-backdrop[data-state='hidden'] {
  opacity: 0;
}
.tippy-backdrop:after {
  content: '';
  float: left;
  padding-top: 100%;
}
.tippy-backdrop + .tippy-content {
  transition-property: opacity;
  will-change: opacity;
}
.tippy-backdrop + .tippy-content[data-state='hidden'] {
  opacity: 0;
}
.tippy-box[data-placement^='top'] > .tippy-svg-arrow {
  bottom: 0;
}
.tippy-box[data-placement^='top'] > .tippy-svg-arrow:after,
.tippy-box[data-placement^='top'] > .tippy-svg-arrow > svg {
  top: $spacer-l;
  transform: rotate(180deg);
}
.tippy-box[data-placement^='bottom'] > .tippy-svg-arrow {
  top: 0;
}
.tippy-box[data-placement^='bottom'] > .tippy-svg-arrow > svg {
  bottom: $spacer-l;
}
.tippy-box[data-placement^='left'] > .tippy-svg-arrow {
  right: 0;
}
.tippy-box[data-placement^='left'] > .tippy-svg-arrow:after,
.tippy-box[data-placement^='left'] > .tippy-svg-arrow > svg {
  transform: rotate(90deg);
  top: calc(50% - $spacer-xxs);
  left: $spacer-m;
}
.tippy-box[data-placement^='right'] > .tippy-svg-arrow {
  left: 0;
}
.tippy-box[data-placement^='right'] > .tippy-svg-arrow:after,
.tippy-box[data-placement^='right'] > .tippy-svg-arrow > svg {
  transform: rotate(-90deg);
  top: calc(50% - $spacer-xxs);
  right: $spacer-m;
}
.tippy-svg-arrow {
  width: $spacer-l;
  height: $spacer-l;
  fill: $gray-1;
  text-align: initial;
}
.tippy-svg-arrow,
.tippy-svg-arrow > svg {
  position: absolute;
}
.tippy-box[data-animation='fade'][data-state='hidden'] {
  opacity: 0;
}
[data-tippy-root] {
  max-width: calc(100vw - ($spacer-s));
}
.tippy-box[data-placement^='top'] > .tippy-arrow {
  bottom: 0;
}
.tippy-box[data-placement^='top'] > .tippy-arrow:before {
  bottom: -$spacer-s;
  left: 0;
  border-width: $spacer-s $spacer-s 0;
  border-top-color: initial;
  transform-origin: center top;
}
.tippy-box[data-placement^='bottom'] > .tippy-arrow {
  top: 0;
}
.tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
  top: -$spacer-s;
  left: 0;
  border-width: 0 $spacer-s $spacer-s;
  border-bottom-color: initial;
  transform-origin: center bottom;
}
.tippy-box[data-placement^='left'] > .tippy-arrow {
  right: 0;
}
.tippy-box[data-placement^='left'] > .tippy-arrow:before {
  border-width: $spacer-s 0 $spacer-s $spacer-s;
  border-left-color: initial;
  right: -$spacer-s;
  transform-origin: center left;
}
.tippy-box[data-placement^='right'] > .tippy-arrow {
  left: 0;
}
.tippy-box[data-placement^='right'] > .tippy-arrow:before {
  left: -$spacer-s;
  border-width: $spacer-s $spacer-s $spacer-s 0;
  border-right-color: initial;
  transform-origin: center right;
}
.tippy-box[data-inertia][data-state='visible'] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}
.tippy-arrow:before {
  content: '';
  position: absolute;
  border-color: $transparent;
  border-style: solid;
}
.tippy-content {
  position: relative;
  padding: $spacer-xs $spacer-s;
  z-index: 1;
  overflow-wrap: break-word;
}

.dashletTypes__dashlet-preview-img {
  max-width: 30rem;
}

// quill plugin
.ql-snow.ql-toolbar button:hover .ql-stroke {
  stroke: var(--primary600);
}

.ql-snow.ql-toolbar button:hover .ql-fill {
  fill: var(--primary600);
}

.ql-snow.ql-toolbar button:hover {
  color: var(--primary600);
}

// flatpickr

.flatpickr-day.selected:hover,
.flatpickr-day.selected {
  background: var(--primary600);
  border-color: var(--primary600);
}

@keyframes progressAnimation {
  0% {
    width: 5%;
  }
  100% {
    width: 100%;
  }
}

.point-container--shadow {
  $border: 0.3rem;
  border-left: $border solid transparent;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-left: 0rem;
    width: $border;
    background: $grey-200;
  }
}

.exampleTooltipFormulaContainer {
  text-align: left;
  span {
    display: block;
    margin-bottom: 5px;
  }
}

.exampleTooltipFormulaCustomField {
  display: inline-flex;
  height: 20px;
  line-height: 16px;
  padding: 0 $spacer-xs;
  margin-inline: $spacer-xs;
  justify-content: center;
  align-items: center;
  gap: $spacer-xs;
  border-radius: $border-radius-m;
  border: $thin-grey-300-border;
  color: $main-blue;
  background: $grey-50;
}
