@use '../../../../../../styles/variables' as v;
@use '../side-panel.scss' as sp;
@use '../../../../../../styles/generic/mixins' as m;
@use '/src/styles/base/typography';

//
// --- --- --- sidePanel Logo Block --- --- ---
//          logo, toggle btn, back btn
//

.sidePanelLogo__logo {
  width: 100%;
  cursor: pointer;
}

.sidePanelLogo__img--hover {
  height: 4.6rem;
  width: 4.6rem;
  display: flex;
  border-radius: v.$border-radius-full;
  margin-left: v.$spacer-s;

  &:hover {
    background-color: var(--panelFont);
  }
}

.sidePanelLogo__img--custom {
  width: auto !important;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 40px;
  max-height: 40px;
}

.sidePanelLogo__img {
  &:focus {
    border-color: inherit;
    box-shadow: none;
  }
}

.sidePanelLogo__img--back {
  cursor: pointer !important;
  margin-left: v.$spacer-m;
  width: v.$spacer-xl;
}

//
// --- --- --- Main Menu --- --- ---
//

.mainNav__element {
  height: 4.6rem;
  margin-left: v.$spacer-s;
  margin-right: v.$spacer-s;
  border-radius: 5rem;
  cursor: pointer;
  @include m.flex-center;
  color: v.$white;

  &:hover {
    color: v.$white;
    background: var(--panelFont);
  }

  @include v.mobile-and-down {
    justify-content: start;
  }
}

.mainNav__element--notifications {
  margin-left: v.$spacer-xs;
  margin-bottom: v.$spacer-xl;
}

.filterPanel__tooltip {
  &:focus {
    border-color: inherit;
    box-shadow: none;
  }
}

.mainNav__name {
  transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
}

.siteNav__element-new-site,
.folderNav__element,
.accountNav__element,
.siteNav__element-empty-folder,
.site-nav__element {
  position: relative;
}

.mainNav__options {
  display: flex;
  margin-left: auto;
}

.mainNav__option {
  border-radius: v.$border-radius-m;
  color: v.$white-60;
}

.mainNav__option--hidden {
  display: none !important;
}

.mainNav__option--disabled {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
  }
}

//
// --- --- --- sidePanelControls --- --- ---
//      logout and profile settings btns
//

.sidePanelControls {
  display: flex;
  justify-content: space-between;
}

.sidePanel__hr {
  margin-left: v.$spacer-l;
  margin-right: v.$spacer-l;
  border-radius: 0;
  border-width: 0.1rem;
  border-color: v.$main-black;
  border-style: solid;
  border-top: none;
  margin-bottom: v.$spacer-xl;
}

.sidePanelUl {
  padding-left: 0;
  margin-bottom: v.$spacer-xl;
}

.sidePanelUl--top {
  margin-top: v.$spacer-m;
  margin-bottom: v.$spacer-xl;
}

.sidePanel__left {
  width: v.$side-panel;
  position: fixed;
  height: 100%;
  display: flex;
  padding: v.$spacer-l v.$spacer-s;
  flex-direction: column;
  align-items: center;
  gap: v.$spacer-l;
  align-self: stretch;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidePanel__mobile-button {
  position: fixed;
  left: v.$spacer-xl;
  top: 1.9rem;
  z-index: 501;
  cursor: pointer;
}

.sidePanel__mobile-button--down {
  top: 6rem !important;
}

.hiddenMobile {
  visibility: hidden;
}

.sidePanelfolder--open {
  width: v.$spacer-xl;
}

.notificationsPanel {
  position: absolute;
  height: 100%;
  width: v.$notifications-panel;
  top: 0;
  left: v.$side-panel;
  background: v.$white;
  z-index: v.$z-index-notificationsPanel;
  overflow: hidden;

  @include v.mobile-and-down {
    left: 0 !important;
    z-index: v.$z-index-notificationsPanel--mobile !important;
    width: 100%;
  }
}

.notificationsPanel--collapsed {
  width: 0;
}

.notificationsPanel--right {
  left: v.$side-panel-expanded;
}

.sidePanelControls__list {
  padding: 0;
  margin: 0;
  width: 6rem;

  @include v.mobile-and-down {
    width: 100%;
  }
}

.sidePanel__close-notifications {
  position: absolute;
  z-index: 501;
  cursor: pointer;
  width: v.$spacer-xxl;
  height: v.$spacer-xxl;
  left: calc(#{v.$side-panel} + #{v.$notifications-panel} + v.$spacer-m);
  top: v.$spacer-m;
  background: v.$white;
  border-radius: v.$border-radius-full;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    .sidePanel__close-notifications-image {
      transform: rotate(180deg);

      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
    }
  }
}

.sidePanel__close-notifications-image {
  @extend .rotateAnimation;
}

.sidePanelUl--landscape {
  margin-top: 0;
  margin-bottom: 0;
}

.sidePanel__left--listWrapper {
  display: contents;
}

.sidePanel__mainContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: v.$spacer-l;
  align-self: stretch;
}

.sidePanel__endContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: v.$spacer-l;
  flex: 1 0 0;
  align-self: stretch;
}
